<template>
<div class="bg-event main-section mt-5 pt-5">
  <div class="events-section bg-third">
    <div class="bg-fourth">
    <b-row class="w-75 m-auto bg-first">
      <b-col cols="8" class="back-ground bg-second mt-5">
        <p class="para-head">수강생들을 위해 준비된 수많은 <span class="para-sub">이벤트 클래스</span> 들도</p>
        <p class="para-head">
        <span class="para-sub-1">  모두 <span class="for-free">무료</span></span>로 즐길 수 있습니다.
        </p>
        <b-row class="w-75 m-auto event-card">
          <b-col class="first-col">
            <div
              v-for="(card, index) in events.slice(0, 2)"
              :key="index"
              class="py-3 cards m-auto"
            >
              <Cards :card="card" background="#FFE63A"/>
            </div>
          </b-col>
          <b-col class="second-col">
            <div
              v-for="(card, index) in events.slice(2, 4)"
              :key="index"
              class="py-3 cards"
            >
              <Cards background="#FFE63A" :card="card"/>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" class="third-col">
        <span>잼있는 영어회화 클래스</span>
        <p class="love-story">Covid 19 Love Story</p>
        <div class="des">
          <p>27살 세희의 일/여행/데이팅을 통해 배우는</p>
          <p>신나는 랜선 영어회화 클래스!</p>
          <p>세희, 그녀가 사랑을 만나기위해 떠나는</p>
          <p>뉴욕여행을 함께 따라가 볼까요?</p>
        </div>
        <div class="mt-5">
          <b-button @click="openRegisterClass" size="lg" class="percent-btn" href="#home">지금 바로 수강신청시 최대 30% 할인 !!</b-button>
        </div>
      </b-col>
    </b-row>
    </div>
  </div>
</div>
</template>

<script>
import Cards from "./cards";
import SigninPopup from './SigninPopup'
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "EventClasses",
  components: {
     SigninPopup,
    Cards
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      tokens: "tokens",
    }),
  },
  data() {
    return {
      events: [
        {
          image: "event1.svg",
          title: "LAN-Sun Language Party",
          content: "덴마크 배우 라쓰머쓰 형님이스라엘에서 여자 장교로 군복무를 했던 니콜과 함께 신나는 이야기 파티를 즐겨보세요."
        },
        {
          image: "event3.svg",
          title: "All night long~",
          content: "켄야에서 온 레아의 아프리카 이야기, 미국에서 온 크리스쳔 등 다양한 선생님들과 야밤에 떠나는 재밌는 ‘문화’ 수업을즐겨보세요."
        },
        {
          image: "event2.svg",
          title: "DJing Party",
          content: "유명클럽 DJ와 멋진팝을 통해 신나고 색다른 영어 공부를 즐겨보세요."
        },
        {
          image: "event4.svg",
          title: "Hip! 한~ Class",
          content: "L.A에서 활동하는 뮤지션들과 래퍼들과 떠나는 힙~한 영어 이야기를 즐겨보세요."
        }
      ]
    };
  },
  methods: {
    openRegisterClass() {
      if (this.tokens && this.tokens.accessToken) {
          this.$router.push("/subscription");
      } else {
        this.$router.push("/auth/login");
      }
    }
  }
};
</script>

<style scoped>

.bg-event {
   background-image: url("~@/assets/newimage/bg-rectangle.svg");
   background-repeat: no-repeat;
   background-position: top left;
  background-size: 1505px 1022px;
   top: 0%;
}

.des {
  letter-spacing: 1.6px;
  font-size: 14px;
  font-weight: 600;
}

.events-section {
  background-image: url("~@/assets/Images/Rectangle.svg");
  background-repeat: no-repeat;
  /* padding-top: 5em;
  padding-bottom: 5em; */
}
.para-head .des p {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.para-head {
  font-weight: 400;
}
.for-free {
  font-weight: bolder;
}

.first-col {
  text-align: center;
}
/* .main-section {
  margin-top: 10em !important;
} */
.second-col {
  margin-top: 70px;
}
.bg-first {
   background-image: url("~@/assets/newimage/Oval-4.svg");
   background-repeat: no-repeat;
   background-position: 68px 282px;
    background-size: 55px;
     animation: 30s animatedBgfirst infinite;
}
@keyframes animatedBgfirst {
  0% {
    background-position: 20% 30%;
  }
  50% {
    background-position: 1% 10%;
  }
  100% {
    background-position: 6% 50%;
  }
}
.bg-second {
   background-image: url("~@/assets/newimage/Oval-2.svg");
   background-repeat: no-repeat;
   background-position: 35px 475px;
    background-size: 15px;
    animation: 30s animatedBgsecond infinite;
}
@keyframes animatedBgsecond {
  0% {
    background-position: 35px 475px;
  }
  50% {
    background-position: -10% -10%;
  }
  100% {
    background-position: 20% 50%;
  }
}
.bg-third {
    background-image: url("~@/assets/newimage/Oval-1.svg");
   background-repeat: no-repeat;
   background-position: 101% 14%;
    background-size: 40px;
     animation: 30s animatedBgthird infinite;
}
@keyframes animatedBgthird {
  0% {
    background-position: 90% 50%;
  }
  50% {
    background-position: 30% 30%;
  }
  100% {
    background-position: 100% 20%;
  }
}
.bg-fourth {
   background-image: url("~@/assets/newimage/Oval-3.svg");
   background-repeat: no-repeat;
     background-position: 56% 91%;
     animation: 30s animatedBgfourth infinite;
}
@keyframes animatedBgfourth {
  0% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 60% 30%;
  }
  100% {
    background-position: 10% 20%;
  }
}
.third-col {
  margin-top: 260px;
}
.love-story {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
}
.percent-btn {
  background: #ff2a58;
  color: white;
  border: none;
  width: 85%;
  padding: 15px;
  font-size: 14px;
}
.percent-btn:hover {
  background: #ff2a58;
  color: white;
  border: none;
  width: 85%;
  padding: 15px;
  font-size: 14px;
}
.para-sub{
  background: rgba(255, 42, 88, 0.6);
  font-weight: bolder;
  font-size: 26px;
}
.para-sub-1{
  background: rgba(255, 230, 58, 0.53);
  font-size: 24px;
}
@media (max-width: 991.98px) {
.event-card{
  width: 100% !important;
}
}
</style>
