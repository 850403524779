<script>
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
  created() {
    // let buff = new Buffer(this.$route.query.data);
    // let base64data = buff.toString('base64');
    // console.log(base64data);
    try {
        const decodedString = this.b64DecodeUnicode(this.$route.query.data);
        console.log(decodedString);
        const obj = JSON.parse(decodedString);
        console.log(obj);
        this.loginPassport({
            user: obj.user,
            tokens: obj.tokens,
        }).then(() => {
            this.$router.push("/");
        });
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    ...mapActions(["loginPassport"]),
    b64DecodeUnicode: function (str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(
        atob(str)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
    },
  },
};
</script>

<template>
  <div>loading...</div>
</template>
