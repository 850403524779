<template>

<div class="bg-expert position-relative">
  <img src="../assets/newimage/Oval Copy 9.svg" alt="mask" class="bg-yellow">
  <img src="../assets/newimage/pink-oval.svg" alt="mask" class="bg-pink">
  <img src="../assets/newimage/semi-oval.svg" alt="mask" class="semi-oval">
  <img src="../assets/newimage/Mask_2.svg" alt="mask" class="position-relative mask w-50">
  <img src="../assets/newimage/on_air.svg" alt="on_air" class="position-absolute on_air w-40">
  <img src="../assets/newimage/on_air_2.svg" alt="on_air_2" class="on_air-2 position-absolute" width="150px" height="150px">
  <img src="../assets/newimage/Dots.svg" alt="dots" class="position-absolute dots">
  <img src="../assets/newimage/geni.svg" class="position-absolute genie" alt="Geni" width="450px" height="450px">
  <img src="../assets/Images/fly_bro.svg" alt="fly_bro" class="position-absolute fly_bro w-50 h-75">
</div>
</template>

<script>

export default {
  name: "FooterAbove"
};
</script>

<style scoped>

.geni {
  position: absolute;
  left: 55%;
  animation: genimove 3s linear infinite;
  animation-direction:alternate-reverse;
}

@keyframes genimove {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(20deg);}
  
}

.bg-expert {
  position: relative;
    top: 0px;
}

.mask {
  right: -50%;
}

.fly_bro {
  left: 0;
  top: 50%;
  transform: translateY(-70%);
}

.on_air {
  z-index: 20;
  right: 0;
  bottom: 0;
}
.on_air-2 {
  z-index: 200;
  bottom: 6em;
  margin-left: -80px;
}

.genie{
  z-index: 40;
  top: -150px;
}

.dots {
  z-index: -1;
  bottom: 0;
  margin-left: -20px;
}
.bg-yellow {
  position: absolute;
  top: 20%;
  left: 6%;
}

.bg-pink {
  position: absolute;
  top: 80%;
  left: 10%;
}

.semi-oval {
  position: absolute;
  top: 75%;
}

.expert-space {
    position: relative;
    top: 10%;
}

.expert {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  color: #242424;
}

.content {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
  color: #535353;
}

.expert-btn {
  background: #FFD602;
  width: 30%;
  aspect-ratio: 1/1;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25); 
}

.z-index-front {
  z-index: 20;
}
.expert-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 20%;
  width: 20%;
  height: 100%;
  transform: skew(-15deg) rotate(-179.71deg);
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 99.98%), #FFD602;
  z-index: 1;
  opacity: 0.2;
}
/* .expert-btn::after {
  content: '';
  position: absolute;
  top: 0;
  right: 20%;
  width: 20%;
  height: 100%;
  transform: skew(-15deg) rotate(-179.71deg);
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 99.98%), #FFD602;
  z-index: 1;
  opacity: 0.2;
} */

.bt-txt {
font-family: "Poppins", sans-serif;
font-style: normal;
font-weight: 500;
font-size: 15px;
padding-right: 85px;
padding-top: 45%;
line-height: 18px;
color: #616161;
text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.bt-txt2 {
font-family: SeoulNamsan CEB;
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 35px;
color: #FFFFFF;
}

.arrow {
  position: relative;
  bottom: -6%;
  left: 36%;
}

</style>