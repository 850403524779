<template>
  <div class="video-card-main-wrap">
    <b-card class="video mt-4">
      <img class="image3" :src="require('@/assets/Images/' + image.logo)" />
      <b-icon
        class="play position-absolute"
        icon="youtube"
        variant="light"
      ></b-icon>
    </b-card>
  </div>
  
</template>

<script>
export default {
  name: "Videocards",
  props: ["image"],
  data() {
    return {};
  }
};
</script>

<style scoped>
.card-body {
  padding: 14px !important;
}
.video {
  background: #ffffff;
  box-shadow: -6px 9px 44px #e6dede;
  border-radius: 8px;
  cursor: pointer;
  width: inherit;
  height: inherit;
}
.image3 {
  width: 100%;
  height: 100%;
}
.play {
  height: auto;
  width: 49px;
  bottom: 50px;
  bottom: 12px;
  right: 27px;
}
.video-card-main-wrap{
  height: inherit;
  width: inherit;
  /* width: 254px;
  height: 294px; */
}

</style>