var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"h-100 mt-5"},[_c('b-colxx',{staticClass:"mx-auto my-auto",attrs:{"xxs":"10","md":"5"}},[_c('div',{staticClass:"position-relative image-side"},[_c('router-link',{attrs:{"tag":"a","to":"/"}},[_c('img',{staticClass:"d-inline-block align-text-top",attrs:{"src":require("@/assets/newimage/Group 1.svg"),"alt":""}})])],1),_c('div',{staticClass:"form-side"},[_c('router-link',{attrs:{"tag":"a","to":"/"}},[_c('span',{staticClass:"logo-single"})]),_c('h2',{staticClass:"mb-4 text-center"},[_vm._v(_vm._s("login"))]),_c('b-form',{staticClass:"av-tooltip tooltip-label-bottom",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"has-float-label mb-4",attrs:{"label":'email'}},[_c('b-form-input',{attrs:{"type":"text","state":!_vm.$v.form.email.$error},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", $$v)},expression:"$v.form.email.$model"}}),(!_vm.$v.form.email.required)?_c('b-form-invalid-feedback',[_vm._v("Please enter your email address")]):(!_vm.$v.form.email.email)?_c('b-form-invalid-feedback',[_vm._v("Please enter a valid email address")]):(!_vm.$v.form.email.minLength)?_c('b-form-invalid-feedback',[_vm._v("Your email must be minimum 4 characters")]):_vm._e()],1),_c('b-form-group',{staticClass:"has-float-label mb-4",attrs:{"label":'password'}},[_c('b-form-input',{attrs:{"type":"password","state":!_vm.$v.form.password.$error},model:{value:(_vm.$v.form.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.password, "$model", $$v)},expression:"$v.form.password.$model"}}),(!_vm.$v.form.password.required)?_c('b-form-invalid-feedback',[_vm._v("Please enter your password")]):(
              !_vm.$v.form.password.minLength || !_vm.$v.form.password.maxLength
            )?_c('b-form-invalid-feedback',[_vm._v("Your password must be between 4 and 16 characters")]):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-start flex-column"},[_c('router-link',{attrs:{"tag":"a","to":"/auth/forgot-password"}},[_vm._v(_vm._s("forgot password?"))]),_c('b-button',{staticClass:"resend-code-cls",attrs:{"variant":"link"},on:{"click":_vm.resendVerificationCode}},[_vm._v("resend verification code")]),_c('router-link',{attrs:{"tag":"a","to":"/auth/register"}},[_c('span',{staticStyle:{"color":"white !important","background-color":"#ff2a58","border-radius":"4px","padding":"4px"}},[_vm._v(_vm._s("회원가입"))])])],1),_c('b-button',{class:{
              'btn-multiple-state btn-shadow': true,
              'show-spinner': _vm.processing,
              'show-success': !_vm.processing && _vm.loginError === false,
              'show-fail': !_vm.processing && _vm.loginError,
            },attrs:{"type":"submit","variant":"primary","size":"lg","disabled":_vm.processing}},[_c('span',{staticClass:"spinner d-inline-block"},[_c('span',{staticClass:"bounce1"}),_c('span',{staticClass:"bounce2"}),_c('span',{staticClass:"bounce3"})]),_c('span',{staticClass:"icon success"},[_c('i',{staticClass:"simple-icon-check"})]),_c('span',{staticClass:"icon fail"},[_c('i',{staticClass:"simple-icon-exclamation"})]),_c('span',{staticClass:"label"},[_vm._v(_vm._s("login"))])])],1)],1),_c('hr',{staticClass:"my-4"}),_c('div',{staticClass:"d-lg-flex justify-content-between align-items-center"},[_c('b-button',{staticClass:"mb-1 mx-1 w-100",attrs:{"variant":"outline-primary"},on:{"click":_vm.googleLogin}},[_c('b-icon',{staticClass:"px-3",attrs:{"icon":"google"}}),_vm._v(" Google")],1),_c('b-button',{staticClass:"mb-1 w-100 mx-1",attrs:{"disabled":"","variant":"outline-secondary"},on:{"click":_vm.instagramLogin}},[_c('b-icon',{staticClass:"px-3",attrs:{"icon":"instagram"}}),_vm._v(" Instagram")],1),_c('b-button',{staticClass:"mb-1 mx-1 w-100",attrs:{"variant":"outline-primary"},on:{"click":_vm.kakaoLogin}},[_c('img',{staticClass:"d-inline-block align-text-top",attrs:{"src":require("@/assets/Images/KakaoTalk.svg"),"alt":"","height":"24"}}),_vm._v(" KakaoTalk")])],1)],1)]),_c('b-modal',{ref:"login_notification_modal",staticClass:"modal-2",attrs:{"body-class":"bg-white","header-class":"bg-white","hide-footer":"","title":_vm.notiModalTitle}},[_c('span',[_vm._v(_vm._s(_vm.notiModalMsg))]),(_vm.notiModalError)?_c('b-button',{staticClass:"mt-4",attrs:{"size":"sm","variant":"warning","block":""},on:{"click":_vm.hideModal}},[_vm._v("Ok")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }