<template>
  <div id="app-container">
    <navbar />
    <main>
        <slot></slot>
    </main>
  </div>
</template>

<script>
import NavBar from "../components/Navbar";

export default {
  components: {
    navbar: NavBar,
  },

};
</script>
