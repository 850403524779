<template>
  <b-row class="h-100 mt-5">
    <b-colxx xxs="10" md="5" class="mx-auto my-auto">
      <!-- <b-card class="auth-card" no-body> -->
      <div class="position-relative image-side">
        <router-link tag="a" to="/"
          ><img
            src="@/assets/newimage/Group 1.svg"
            alt=""
            class="d-inline-block align-text-top"
        /></router-link>
        <!-- <p class="white mb-0">
          <br />If you are not a member, please
          <router-link tag="a" to="/auth/register" class="white"
            >register</router-link
          >.
        </p> -->
      </div>
      <div class="form-side">
        <router-link tag="a" to="/">
          <span class="logo-single" />
        </router-link>
        <h2 class="mb-4 text-center">{{ "login" }}</h2>

        <b-form
          @submit.prevent="formSubmit"
          class="av-tooltip tooltip-label-bottom"
        >
          <b-form-group :label="'email'" class="has-float-label mb-4">
            <b-form-input
              type="text"
              v-model="$v.form.email.$model"
              :state="!$v.form.email.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.email.required"
              >Please enter your email address</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else-if="!$v.form.email.email"
              >Please enter a valid email address</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else-if="!$v.form.email.minLength"
              >Your email must be minimum 4 characters</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group :label="'password'" class="has-float-label mb-4">
            <b-form-input
              type="password"
              v-model="$v.form.password.$model"
              :state="!$v.form.password.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.password.required"
              >Please enter your password</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-else-if="
                !$v.form.password.minLength || !$v.form.password.maxLength
              "
              >Your password must be between 4 and 16
              characters</b-form-invalid-feedback
            >
          </b-form-group>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start flex-column">
              <router-link tag="a" to="/auth/forgot-password">{{
                "forgot password?"
              }}</router-link>
              <b-button class="resend-code-cls" variant="link" @click="resendVerificationCode">resend verification code</b-button>
              <router-link tag="a" to="/auth/register"
                ><span
                  style="
                    color: white !important;
                    background-color: #ff2a58;
                    border-radius: 4px;
                    padding: 4px;
                  "
                  >{{ "회원가입" }}</span
                ></router-link
              >
            </div>
            <b-button
              type="submit"
              variant="primary"
              size="lg"
              :disabled="processing"
              :class="{
                'btn-multiple-state btn-shadow': true,
                'show-spinner': processing,
                'show-success': !processing && loginError === false,
                'show-fail': !processing && loginError,
              }"
            >
              <span class="spinner d-inline-block">
                <span class="bounce1"></span>
                <span class="bounce2"></span>
                <span class="bounce3"></span>
              </span>
              <span class="icon success">
                <i class="simple-icon-check"></i>
              </span>
              <span class="icon fail">
                <i class="simple-icon-exclamation"></i>
              </span>
              <span class="label">{{ "login" }}</span>
            </b-button>
          </div>
        </b-form>
        <hr class="my-4" />
        <div class="d-lg-flex justify-content-between align-items-center">
          <b-button
            class="mb-1 mx-1 w-100"
            variant="outline-primary"
            @click="googleLogin"
            ><b-icon icon="google" class="px-3"></b-icon> Google</b-button
          >
          <b-button
            disabled
            class="mb-1 w-100 mx-1"
            variant="outline-secondary"
            @click="instagramLogin"
            ><b-icon icon="instagram" class="px-3"></b-icon> Instagram</b-button
          >
          <b-button
            class="mb-1 mx-1 w-100"
            variant="outline-primary"
            @click="kakaoLogin"
            ><img
              src="@/assets/Images/KakaoTalk.svg"
              alt=""
              height="24"
              class="d-inline-block align-text-top"
            />
            KakaoTalk</b-button
          >
        </div>
      </div>
      <!-- </b-card> -->
    </b-colxx>
    <b-modal class="modal-2" body-class="bg-white" header-class="bg-white" ref="login_notification_modal" hide-footer :title="notiModalTitle">
      <span>{{notiModalMsg}}</span>
      <b-button v-if="notiModalError" size="sm" class="mt-4" variant="warning" block @click="hideModal">Ok</b-button>
    </b-modal>
  </b-row>

  <!-- <form>
    <p class="h4 text-center mb-4">Sign in</p>
    <label for="defaultFormLoginEmailEx" class="grey-text">Your email</label>
    <input type="email" id="defaultFormLoginEmailEx" class="form-control"/>
    <br/>
    <label for="defaultFormLoginPasswordEx" class="grey-text">Your password</label>
    <input type="password" id="defaultFormLoginPasswordEx" class="form-control"/>
    <div class="text-center mt-4">
      <button class="btn btn-indigo" type="submit">Login</button>
    </div>
  </form> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
const {
  required,
  maxLength,
  minLength,
  email,
} = require("vuelidate/lib/validators");
import { adminRoot, apiAuthUrl } from "../../constants/config";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      notiModalTitle: '',
      notiModalMsg: '',
      notiModalError: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(4),
      },
      email: {
        required,
        email,
        minLength: minLength(4),
      },
    },
  },
  computed: {
    ...mapGetters(["currentUser", "processing", "loginError"]),
  },
  methods: {
    ...mapActions(["login", "resendCode"]),
    hideModal() {
      this.$refs['login_notification_modal'].hide();
    },
    formSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.login({
          email: this.form.email,
          password: this.form.password,
        });
      }
    },
    lineLogin() {
      window.open(`${apiAuthUrl}/v1/line-auth/login`, "_self");
    },
    googleLogin() {
      window.open(`${apiAuthUrl}/v1/google-auth/login`, "_self");
    },
    instagramLogin() {
      window.open(`${apiAuthUrl}/v1/instagram-auth/login`, "_self");
    },
    kakaoLogin() {
      window.open(`${apiAuthUrl}/v1/kakao-auth/login`, "_self");
    },
    resendVerificationCode() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.resendCode({
          email: this.form.email,
        }).then(
          (response) => {
            this.$notify(
              "success",
              "Resend Success",
              response.data.message ? response.data.message : "Please check your email.",
              {
                duration: 6000,
                permanent: false,
              }
            );
          },
          (err) => {
            this.$notify(
              "error",
              "Resend Code Error",
              err.response.data.message,
              {
                duration: 3000,
                permanent: false,
              }
            );
          }
        );
      }
    },
  },
  watch: {
    currentUser(val) {
      if (val && val.uid && val.uid.length > 0) {
        setTimeout(() => {
          // this.$router.push(adminRoot);
          this.$router.push(this.$store.getters.path);
        }, 200);
      }
    },
    loginError(val) {
      if (val != null) {
        // this.$notify("error", "Login Error", val, {
        //   duration: 8000,
        //   permanent: false,
        // });
        if (val != null) {
          this.notiModalTitle="Login Error";
          this.notiModalMsg=val;
          this.notiModalError = true;
          this.$refs['login_notification_modal'].show()
        }
      }
    },
  },
  mounted() {
    console.log(this.$store.getters.path);
  }
};
</script>

<style scoped>
.resend-code-cls {
  padding: 0;
  margin-bottom: 15px;
  margin-top: 10px;
}

</style>

