<template>
  <div class="main position-relative">
    <img
      src="../assets/newimage/Rectangle.svg"
      class="bg-hero-1 w-100 position-relative"
      alt="bg-hero-1"
    />
    <img
      src="../assets/newimage/Group 103.svg"
      class="bg-hero d-xs-none position-absolute w-50"
      alt="bg-hero"
    />
    <b-row
      no-gutters
      class="position-absolute section-one w-75 w-xs-100 h-100 p-xs-3"
      align-h="start"
      align-v="start"
    >
      <b-col class="pl-lg-5 pt-custom-6" cols="12">
        <div class="pt-custom-10">
          <div
            data-aos="flip-up"
            data-aos-easing="ease-in"
            class="
              font-family-fugazOne
              bro
              font-size-xxl
              spin
              content
              d-inline-block
              position-relative
            "
          >
            <span class="text-custom-dark"> We are</span>
            <span class="text-custom-secondary"> back! </span>
          </div>
          <div
            class="
              font-family-poppins
              position-relative
              ml-custom-5
              pl-lg-5
              font-size-md
            "
          >
            <span class="d-block">그 동안 준비해 온</span>
            <span class="d-block pl-3"
              >더 좋아진 컨텐츠, 더 많아진 선생님들,</span
            >
            <span class="d-block ml-3"
              >더흥미로워진 스토리들, 더 다양해진 클래스들과</span
            >
            <span class="d-block"
              >함께 온라인 라이브클래스를 새롭게 시작합니다.</span
            >
            <span class="d-block"><b>7월 05일 ~ 8월 29일까지 (총 8주)</b></span>
            <!-- <span class="d-block mt-5 mt-custom-in-xs-2"><b>Mr.Bro</b>와 함께 하는 재미있는 라이브 영어회화 클래스</span> -->
          </div>
          <div class="w-50">
            <span class="d-block mt-5 mt-custom-in-xs-2 font-size-md"
              ><b>Mr.Bro 와 함께 하는 재미있는 라이브 영어회화 클래스</b></span
            >
            <div
              class="d-flex justify-content-between"
              v-if="!disablePaymentConfig"
            >
              <!-- <button :disabled="hasClass"
            @click="openRegisterClass" class="d-block w-100 bg-dark text-white mr-1 font-size-in-xs-xs py-3 border-radius-soft border-0">
           <b>1+1 수강신청</b>
         </button>
         <button :disabled="hasClass || disablePaymentConfig"
            @click="openRegisterClass" class="d-block w-100 ml-1 bg-custom-invert-dark font-size-in-xs-xs py-3 border-radius-soft">
           <b>ALL-PASS 수강신청</b>
         </button> -->
            </div>
            <div class="w-100 my-2" v-if="!disablePaymentConfig">
              <div
                class="d-flex flex-sm-column flex-lg-row flex-xl-row justify-content-between "
                v-if="!disablePaymentConfig"
              >
                <button
                  :disabled="hasClass || disablePaymentConfig"
                  @click="openRegisterClass"
                  class="
                    d-block
                    w-50
                    mr-3
                    mb-3
                    text-white
                    font-size-in-xs-xs
                    py-3
                    bg-custom-secondary
                    border-radius-soft
                  "
                >
                  모든 수업 듣기 / ￦69,000
                </button>
                <button
                  :disabled="hasClass || disablePaymentConfig"
                  variant="link"
                  @click="openScheduleClass"
                  class="
                    d-block
                    w-50
                    mb-3
                    bg-custom-invert-dark
                    font-size-in-xs-xs
                    py-3
                    border-radius-soft
                  "
                >
                  수업시간표 보기
                </button>
              </div>
            </div>
            <div class="w-100 my-2" v-if="disablePaymentConfig">
              <b-button
                :disabled="hasClass || disablePaymentConfig"
                class="
                  d-block
                  w-100
                  text-white
                  font-size-in-xs-xs
                  py-3
                  bg-custom-secondary
                  border-radius-soft
                "
              >
                <b>Registration is now closed!</b>
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- <b-row class="w-75 m-auto top-sm-0 mt-custom-in-sm-2 position-relative cards" align-h="between">
    <b-col
      v-for="(card, index) in cards"
      :key="index"
      xl="4" lg="4" md="4" sm="12" xs="12" align-h="stretch"
      >
        <cards background="#ffffff" :card="card" />
      </b-col>
  </b-row> -->
  </div>
</template>

<script>
import AllPass from "./AllPass";
import Cards from "./cards";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import { httpClient } from "../service";
import { apiAuthUrl, disablePaymentConfig } from "../constants/config";
export default {
  name: "TeachingIntro",
  components: {
    "all-pass": AllPass,
    cards: Cards,
  },
  data() {
    return {
      cards: [
        {
          image: "Object 3.png",
          title: "실시간 온라인 라이브 ",
          content:
            "모든 수업들은 라이브로 진행됩니다. 지루하게 녹음된 인강으로 수업하셨다면, 이제는 실시간 라이브 클래스를 즐기세요! ",
        },
        {
          image: "Object.png",
          title: "다양한 클래스들 ",
          content:
            "재밌고 실력파 쌤들이 모였다. 초급, 중급, 비즈니스, 문화, 스페셜 이벤트 클래스",
        },
        {
          image: "Object 2.png",
          title: "가성비 짱",
          content:
            "2개월(8주)동안 6.9만원으로 2개의 영어수업을 수강할 수 있어요! All-PASS권(8.9만원)으로 모든 수업을 무제한으로 들을 수 있는 가성비 최고의 영어교육을 즐겨보세요.",
        },
      ],
      disablePaymentConfig: disablePaymentConfig,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      tokens: "tokens",
      classTypes: "classTypes",
      enrollmentData: "enrollmentData",
    }),
    hasClass: function () {
      return this.enrollmentData && this.enrollmentData.status === "PAY_DONE"
        ? true
        : false;
    },
  },
  methods: {
    openRegisterClass() {
      this.$router.push("/subscription");
    },
    openScheduleClass() {
      window.open('/img/ce_New_Schedule_Table.128bff02.png', '_blank');
    }
  },
};
</script>

<style scoped>
.bg-hero-1 {
  top: 0;
  left: 0;
  z-index: 1;
}
.bg-hero {
  top: 0;
  right: -1em;
  z-index: 2;
}
.section-one {
  z-index: 10;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.bro {
  font-style: normal;
  font-weight: 500;
  transform: rotate(-15deg) !important;
}

.spin {
  top: -40px;
}
.cards {
  top: -15em;
  z-index: 5;
}
</style>