<template>
  <div class="comment-section">
    <b-row no-gutters class="w-75 m-auto">
      <b-col>
        <p class="header ml-0"><span class="one">1</span>기 수강생의 코멘트 <span class="subComment">* 수강생들의 모든 후기는 Instagram Mr.Bro 채널에서 확인하실 수 있습니다. </span></p>
        <b-card
          class="my-3 p-3 card"
          v-for="(content, index) in Comments"
          :key="index"
        >
          <div class="date">
            <span class="font-weight-bold">Mr.Bro </span>
            <span> 1기 수강생</span>
          </div>
          <p class="font-weight-bold title">{{ content.title }}</p>
          <div class="mt-2 description">
            <p v-for="(desc, i) in content.description" :key="i">{{ desc }}</p>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Comments",
  data() {
    return {
      Comments: [
        {
          title: "재수강하러 갑니당 좋은 강의부탁드려요❤️❤️❤️",
          description: [
            "안녕하세요:) 저는 청강으로 강의를 수강했던 학생입니다! 직장인인데 보통 영어강의는 실생활에서 쓰는 표현들이 적어서 막상 배우면 쓸곳이 많이 없었는데, 화상강의는 딱 평상시에 쓸만한 표현이 많아서 좋았어요! 특히 욕 강의를 제일 기대하면서 들었는데 너무 좋았습니닼ㅋㅋㅋㅋ 잘 써먹을수 있을꺼같아요ㅎㅎㅎㅎㅎㅎ,, 가끔 브이아이피 분들이 없는 수업때는 선생님이 너무 고생하시는것같아 맘이 아팟지만 월 수 수업이 상대적으로 수강생이 많은거같아 월 수 수업에 더 많이 참여햇다는거ㅎㅎ,, 재수강하러 갑니당 좋은 강의부탁드려요❤️❤️❤️",
          ],
        },
        {
          title: "제 1기 수업 총평은요!!  저 재수강이요!!!",
          description: [
            `우선 10주 동안 너무너무 고생하셨고,감사했습니다.매일 월부터 목까지 무려 4시간씩 연달아 하시는 수업에 힘든 기색 하나 없이 수업하시던 모습 넘넘 존경스러웠어요!하물며 시간이 갈수록 수업 내욘이 더 좋아지는건 무엇?ㅋㅋ덕분에 저도 시간여유가 있을땐 두시간 세시간 연강 들을적도 많고요.복습까지 그날 끝낼수 있어서 넘나 이득!ㅋ`,
            `무엇보다 좋았던건 생활영어 상황별 연습이랑 슬랭같은 흔히 접하지 못하는 단어들을 많이 들을수 있었던 거였어요.조금 부끄러서 vip공석 일때 더 적극적으로 참여하지 못했던건 후회스럽네요ㅜㅜ그래도 집에선 누구보다 큰소리로 참여했습니당ㅋㅋ수업후에 미드 시청을 하는데 쌤께 배웠던 문장들은 막막 들리는 경험.완전 소름이었어요!!너무 감사드려요!!`,
            `한가지 아쉬웠던건 수강생분들과 역활분담 스피치 후 그문장 발음을 더 정확하게 쌤 발음으로 들을수 없을 때가 였어요.가끔 발을이 어?이상한데 할때가 있는데 vip분들만 읽고 지나갈때 조금 아쉬웠거든요.그럴땐 제가 단어장 발음 다시 찾아서 공부하고...크게 불편했던건 아니고 가끔이요ㅋㅋ
그래서 제 1기 수업 총평은요!!`,
            `저 재수강이요!!!`,
            `그리고 친구까지 꼬셔서 수업 신청시켰어유~ 친구도 할인해주셔야해욧!!ㅋㅋ`,
          ],
        },
        {
          title:
            "이제 퇴근길에 음악듣거나 유툽 보거나 쇼핑하거나 하지않고 공부합니다~~^^",
          description: [
            `항상 영어 회화 공부에 갈증을 느끼고  회회 실력이 부족한만큼  여기 저기 여러 선생님 강의 기웃거리고 인스타그램 컨텐츠.유툽 컨텐츠 로  제 갈증을 채우고 있었습니다.그 중에 브로 쌤도 계셨어요..`,
            `항상 브로쌤 컨텐츠를 보면서 도대체  이런 훌륭한 컨텐츠를 만드시는분은 누굴까 궁금했었어요.그런데 제가 그 분 의 강의를 듣게될 줄은 꿈에도 몰랐어요.
첫 수업을 하러가는 날 기분을 잊을수없어요. 그리고 비록 지금은 오프라인 수업은 아니지만, 막상 하다보니 직접수업보다 온라인 수업이 저한테 더 효율적이고 좋더라구요.`,
            `1.항상 영어의 끈을 놓지 않으며  이 강의 저 강의.~이학원 저학원 다니면서 여러 컨텐츠를 접했는데,
브로쌤 수업내용은 진짜 지금까지 한번도 배워보지 못한 내용이였어요. 진짜 원어민이 사용하는 표현이랄까? 너무뿌듯했어요.`,
            `2. 화상수업이다보니 퇴근길 지하철에서 수업하기 너무좋았어요.지하철안에서 수업듣다보니 VIP수업참가는 못하지만 그래도 남들 보다 시간을 효율적으로 쓰는 것 같아 뿌듯했어요`,
            `3.언제든 수업 횟수제한 없이 참여가능해서 자동적으로 복습효과까지 되더라구요~`,

            `이제 퇴근길에 음악듣거나 유툽 보거나 쇼핑하거나 하지않고 공부합니다~~^^
감사합니다 쌤. `,
          ],
        },
        {
          title: "매주 보내주시는 수업자료가 정말 퀄리티가 좋아요!!",
          description: [
            `안녕하세요 브로쌤~  저는 일반학생으로 참여했던 yoon 이라고 합니다!  평소에 영어공부에는 관심이 많지만 사실 책을 사도 독학으로 보면서 공부하기가 힘들더라구요.(의지박약 스타일)`,

            `그렇다고 넷플릭스로 영어자막+한국자막 같이 보는데에 한계도 있고..  그러던 찰나에! 영어에 도움이 되는 인스타 몇개를 구독하고있었고, 브로쌤 팔로우한 지도 얼마 안됐을 때였는데 코로나 때문에 온라인강의로 수업을 진행하신다고하더라구요!`,

            `회화는 거의 되지않기 때문에 전화영어같은 수업도 사실은 많이 부담스러웠었고, 가격도 너무 저렴하고 일주일에 두 번만 들으면 되니까 괜찮을 것 같더라구요.
그래서 신청하게되었답니다.`,

            `일단 제 생각에 좋았던 점은 zoom 어플을 통한 실시간 강의라서 좋았던 것 같아요.
인터넷 강의처럼 올라오는 거는 사실 혼자 맘 잡고 보기가 힘들어요.`,

            `그런데 실시간으로 강의를 하다보니 무슨 일이 있어도 일주일에 두 번은 들어야겠단 생각으로 스케줄 조정을 해서 완강했구요.
똑같은 수업을 더 들은 적은 없지만 잘 이해가 안되는 강의는 여러번 반복해서 청강하면 더 이해하기 쉬울 것 같아요.`,

            `실시간 강의이기 때문에 vip학생은 아니지만 채팅창에 질문을 하면 답변을 해주셔서 소통하는 느낌이 들어서 조금 더 집중이 잘 되었던 것 같아요.
아, 그리고 매주 보내주시는 수업자료가 정말 퀄리티가 좋아요!!`,

            `다양한 예시, 대화 등으로 이루어져있어서 '아~ 이런 때에 이런 표현을 사용하는구나.' 조금 더 이해가 잘 되었던 것 같아요.
복습을 열심히하지 못했지만 다시 초심으로 돌아가서 열심히 하도록 하겠습니다! :)`,
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.header {
  font-weight: 800;
  font-family: sans-serif;
  margin-left: 200px;
}
.comment-section {
  background-image: url("~@/assets/Images/bggray.svg");
  background-repeat: no-repeat;
  background-position: 400px 5px;
}
.card {
  border: 0 !important;
}
.one {
  font-size: 100px;
  color: #ffd602;
  font-style: italic;
  font-weight: bolder;
}
.header {
  font-size: 54px;
}

.date {
  color: #979797;
  font-size: 10px;
}
.description {
  font-size: 12px;
}
.card {
  box-shadow: 1px 1px 15px #eeeeee;
}
.subComment{
  font-size: 8px;
  color: #FF2A58;
;
}
</style>