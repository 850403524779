<template>
    <div>
        <!-- <PageLayout> -->
            <div class="d-lg-flex w-75 pt-5 m-auto justify-content-around section">
                <div class="my-sm-4 p-1 w-90">
                    <Modal
                    class="m-auto subscription_modal"
                    subscription_type="all_pass"
                    subscription_grade="NORMAL"
                    :subscription_price="69000"
                    @payment_success="$emit('payment_success')"/>
                </div>
                <div class="my-sm-4 p-1 w-90">
                    <Modal
                    class="m-auto subscription_modal"
                    :subscription_price="69000"
                    subscription_type="all_pass"
                    subscription_grade="VIP"
                    @payment_success="$emit('payment_success')"/>
                </div>
            </div>
        <!-- </PageLayout>    -->
    </div>
</template>
<script>
import Modal from './modal2';
// import PageLayout from '../layouts/pageLayout'
export default {
    name: 'SubscriptionModal',
    components: {
        Modal,
        // PageLayout
    }
}
</script>

<style scoped>
.section {
    border-top: 1px solid #D6D6D6;
}

.subscription_modal {
    width: 500px;
    height: 500px;
    /* aspect-ratio: 1/1 !important; */
}
</style>