<template>
  <v-row>
    <v-col>
        <div>
            <b-button variant="light" @click="type='day'">
                Day
            </b-button>
            <b-button variant="light" @click="type='week'">
                Week
            </b-button>
            <b-button variant="light" @click="type='month'">
                Month
            </b-button>
            <!-- <b-button variant="light" @click="type='year'">
                Year
            </b-button> -->
        </div>
      <v-sheet height="400">
        <v-calendar
          ref="calendar"
          :now="today"
          :value="today"
          :events="events"
          event-color="blue"
          color="primary"
          :type="type"
          :weekdays="week"
          first-time="09:00"
          interval-count=13
        ></v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    data: () => ({
      today: '2019-01-08',
      events: [
        {
          name: 'Weekly Meeting',
          start: '2019-01-07 09:00',
          end: '2019-01-07 10:00',
          color: 'blue'
        },
        {
          name: `Thomas' Birthday`,
          start: '2019-01-10',
          color: 'blue'
        },
        {
          name: 'Mash Potatoes',
          start: '2019-01-09 12:30',
          end: '2019-01-09 15:30',
          color: 'blue'
        },
      ],
      type:'day',
      week:[1, 2, 3, 4, 5, 6, 0]
    }),
    mounted () {
      this.$refs.calendar.scrollToTime('08:00')
    },
  }
</script>
<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
</style>