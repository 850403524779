<template>
 <div class="main-section">
  
            <TeachingIntro ref="home"/>
            
            <div class="row w-75 mx-auto p-0 card-position">
            <b-col
              v-for="(card, index) in cards"
              :key="index"
              cols="4" align-h="stretch"
            >
              <Cards background="#ffffff" :card="card" />
            </b-col>
            </div>
        <Imageslider ref="english-classes"/>
            <!-- <VideoscardWrapper ref="teachers"/> -->
            <SigninPopup/>
              <EventClasses  ref="event-classes"/>
             <VipClass />
            <Fun />
          <FooterAbove />
      <Comments />
      <Footer />
  </div>
</template>

<script>
import TeachingIntro from "./components/TeachingIntro.vue";
import Cards from "./components/cards";
import VideoscardWrapper from "./components/VideoscardWrapper";
import Imageslider from "./components/Imageslider";
import FooterAbove from "./components/FooterAbove.vue";
import EventClasses from "./components/EventClasses";
import Footer from "./components/Footer";
import Comments from "./components/Comments";
import VipClass from './components/VipClass';
import Navbar from './components/Navbar.vue';
import Fun from './components/fun.vue';
import SigninPopup from './components/SigninPopup1';

export default {
  name: "NewApp",
  components: {
    Navbar,
    TeachingIntro,
    VideoscardWrapper,
    FooterAbove,
    Comments,
    Footer,
    EventClasses,
    Imageslider,
    Fun,
    Cards,
    VipClass,
    SigninPopup,
  },
  data() {
    return {
      cards: [
        {
          image: "Object 3.png",
          title: "실시간 온라인 라이브 ",
          content:
            "모든 수업들은 라이브로 진행됩니다. 지루하게 녹음된 인강으로 수업하셨다면, 이제는 실시간 라이브 클래스를 즐기세요! "
        },
        {
          image: "Object.png",
          title: "다양한 클래스들 ",
          content:
            "재밌고 실력파 쌤들이 모였다. 초급, 중급, 비즈니스, 문화, 스페셜 이벤트 클래스"
        },
        {
          image: "Object 2.png",
          title: "가성비 짱",
          content:
            "2개월(8주)동안 6.9만원으로 2개의 영어수업을 수강할 수 있어요! All-PASS권(8.9만원)으로 모든 수업을 무제한으로 들을 수 있는 가성비 최고의 영어교육을 즐겨보세요."
        }
      ]
    };
  },
   watch: {
    '$store.getters.hashPath': function (v) {
      this.$refs[v].$el.scrollIntoView();
    }
  },
  mounted() {
    console.log('hello world mounted');
    if (this.$refs[this.$store.getters.hashPath]) {
      this.$refs[this.$store.getters.hashPath].$el.scrollIntoView();
    }
  }
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

.main-section {
  overflow: hidden;
}

.events{
  margin-top: -15em;
}
.pg-down {
  position: relative;
  top: 30%;
  left: 0%;
}

.card-position {
  position: relative;
  margin-top: -15em;
  z-index: 100;
}
</style>
