<template>
<b-container fluid class="vip-bg py-5">
<b-row class="w-75 m-auto " no-gutters>
   <b-col class="d-flex align-items-center justify-content-center">
       <img width="50px" height="50px" src="../assets/Images/vip_inactivated.svg" alt=""/>
      <span class=" h2 font-weight-bold d-block m-0 text-dark">VIP Class</span> 
      </b-col>
</b-row>
<b-row class="w-75 m-auto py-5" no-gutters>
    <b-col class="mx-1 mt-3" lg="6" xl="6" md="12" sm="12">
        <img class="w-100 h-auto" src="../assets/Images/vip_card_1.svg" alt="" />
    </b-col>
        <b-col class="mx-1 mt-3">
        <img class="w-100 h-auto" src="../assets/Images/vip_card_2.svg" alt=""/>
    </b-col>
</b-row>
<b-row class="w-75 m-auto" no-gutters>
    <b-col>
   <p class="w-75 m-auto text-center font-weight-bold">위와 같이 <span> VIP 학생들 </span>은 선택한 특정한 수업 시간대에 <span> 카메라와 마이크를 ON 상태 </span>에서 선생님과 함께 <span> 실시간 소통 </span>을 하면서 수업을 합니다. 
반면, 일반 수강생들은 카메라와 마이크를 OFF 한 상태에서 실시간으로 수업을 ‘시청’하실 수 있으며, 소통은 채팅창으로 하실 수 있습니다
</p>
    </b-col>
</b-row>
</b-container>
</template>
<script>
export default {
    name:"vip"    
}
</script>

<style scoped>
.vip-bg{
    background-color: #FFD602;
}
span{
    color: #FF2A58;
}
p{
    font-size: 12px;
    line-height: 2em;
}
</style>