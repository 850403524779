<template>
    <b-container fluid class="w-75 mx-auto p-0 my-custom">
      <b-row no-gutters align-h="around">
        <b-col
          v-for="(fun, index) in funtext"
          :key="index"
          class="my-5"
          cols="3"
        >
          <div id="bg-pink">
            <span class="fun">{{ fun.title }}</span>
            <span class="fun2 pl-1">{{ fun.titlesub }}</span>
            <p class="fun-txt">{{ fun.content }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {
  name: "fun",
  data() {
    return {
      funtext: [
        {
          title: "FUN",
          titlesub: "재밌어야 한다.",
          content:
            "Mr.Bro English의 모토 중 첫 번째 입니다. 저희는 여러분께서 최대한 재밌게 영어를 습득하실 수 있는데 노력을 합니다.",
        },
        {
          title: "CULTURAL ",
          titlesub: "문화적이어야 한다.",
          content:
            "영어는 어디서든 배울 수 있습니다. 하지만 저희는 문화적 가르침을 중요시합니다. 다른 나라 사람들과 소통하기 위해서는 언어 뿐만 아니라 문화를 알아야합니다.",
        },
        {
          title: "PRACTICAL",
          titlesub: "실용적이어야 한다.",
          content:
            "오래된 옛날 자료들을 계속 우려먹지 않습니다. 저희 수업자료들은 ‘실용적’입니다. ‘실용’ 과 ‘효율’을 바탕으로 하나 하나 Mr.Bro 팀에서 직접 만든 콘텐츠입니다.",
        },
      ],
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;900&display=swap");

.my-custom {
  margin: 5rem 0;
}

#bg-pink {
  position: relative;
}

#bg-pink::before {
  content: "";
  position: absolute;
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #ffd602;
  opacity: 0.2;
  transform: rotate(-179.71deg);
  width: 90%;
  height: 100%;
  z-index: -1;
  transform: skew(-19deg);
}

.fun {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 65px;
  color: #242424;
}

.fun2 {
  font-family: SeoulHangang;
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  color: #ff2a58;
}

.fun-txt {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  color: #535353;
}
</style>