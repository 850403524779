<template>
  <div class="main-section mt-custom-5 position-relative">
    <img
      src="@/assets/newimage/TriangleCopy 2.svg"
      alt=""
      class="triangle position-absolute"
    />
    <img
      src="@/assets/newimage/OvalCopy 5.svg"
      alt=""
      class="circle position-absolute"
    />
    <img
      src="@/assets/newimage/Triangle Copy.svg"
      alt=""
      class="triangle-sm position-absolute"
    />
    <img
      src="@/assets/newimage/Oval.svg"
      alt=""
      class="circle-inner position-absolute"
    />
    <img
      src="@/assets/newimage/OvalCopy 5.svg"
      alt=""
      class="triangle-lg position-absolute"
    />
    <b-carousel
      v-model="currentCircle"
      :interval="4000"
      class="position-relative"
      ref="carousel"
    >
      <button
        @click="$refs.carousel.prev()"
        class="position-absolute controller ml-5"
      >
        <img
          src="@/assets/newimage/left-arrow.svg"
          alt="left-arrow"
          width="80%"
        />
      </button>
      <button
        @click="$refs.carousel.next()"
        class="position-absolute rightarrow mr-5"
      >
        <img
          src="@/assets/newimage/right-arrow.svg"
          alt="right-arrow"
          width="80%"
        />
      </button>

      <div class="text-center">
        <button
          @click="$refs.carousel.setSlide(index)"
          v-for="(card, index) in Cards"
          :key="index"
          :class="[
            'indicators mx-2',
            { 'active-circle': currentCircle === index },
          ]"
        ></button>
      </div>

      <b-carousel-slide v-for="(card, index) in Cards" :key="index">
        <template #img>
          <!-- <b-card> -->
          <b-row
            class="w-75 m-auto row-section d-flex flex-wrap"
            align-h="center"
            align-v="center"
          >
            <b-col class="text-center">
              <img
                src="@/assets/newimage/Mask.svg"
                alt=""
                class="position-absolute img1 left-sm-50"
              />
              <img
                :src="require('../assets/newimage/' + card.image)"
                alt=""
                class="position-relative img2 left-sm-0 rounded"
              />
            </b-col>
            <b-col class="height-fit-content">
              <img
                class="mb-3 mask position-relative"
                src="../assets/newimage/copy.svg"
                alt="quotes"
              />
              <span class="heading ml-3"> {{ card.title }}</span>
              <span class="heading base-title"> {{ card.titlebase }}</span>
              <p
                class="content ml-5 mb-0"
                v-for="(para, index) in card.content"
                :key="index"
              >
                {{ para }}
              </p>
              <span class="content english ml-5">{{ card.english }}</span>
              <div class="mt-5 ml-5">
                <p class="ceo-name mb-0 text-dark">
                  {{ card.classMaterial }}
                </p>
                <a
                  :href="'/img/' + card.document"
                  target="_blank"
                  style="text-decoration: none"
                >
                  <span
                    v-if="card.showViewDetail === true"
                    class="cc see-detail"
                    >{{ card.viewDetail }}</span
                  >
                </a>
                <span
                  @click="openCalendar"
                  class="cc view"
                  style="padding-left: 6px; font-size: 20px; cursor: pointer"
                  >{{ card.viewTwo }}</span
                >
              </div>
            </b-col>
          </b-row>
          <!-- </b-card> -->
        </template>
      </b-carousel-slide>
    </b-carousel>
    <b-modal
      body-class="calander-modal"
      id="calendar-modal-wrap"
      content-class="p-0"
      scrollable
      ref="calendar-modal"
      centered
      hide-footer
      hide-header
      size="xl"
    >
      <img
        src="../assets/Images/New_Schedule_Table.png"
        alt="scheduled_table"
        class="calendar"
      />
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Imageslider",
  data() {
    return {
      currentCircle: 0,
      Cards: [
        {
          image: "IMG_8288.png",
          title: "일상 회화 수업",
          titlebase: "Daily Conversation",
          content: [
            "Mr.Bro English 채널의 ‘핵심’ 클래스 입니다. 수강생들에게 가장 인기가 많은 클래스랍니다.",
            "생감있는 등장 인물들과 실제로 있을 법한 흥미롭고 드라마틱한 상황들을 바탕으로 스토리가 이루어 집니다.각 레슨에는 여러분의 배움의 ‘효율성’을 높이기 위해서 ‘토픽’이 정해져 있습니다.이번 시즌에는 서울여자 Jennie가 코로나로 인해서 데이팅어플을 사용하기 시작하고, 거기서 혼혈 훈남 Eli를 만나게 됩니다.",
          ],
          classMaterial: "수업 커리큘럼 & 수업 자료 ",
          viewDetail: "상세내용",
          showViewDetail: true,
          viewTwo: "수업시간표 보기",
          document: "ce_Regular_Explanation.c35144aa.png",
        },
        {
          image: "IMG_8281.png",
          title: "초보 회화 수업",
          titlebase: " Beginner Class",
          content: [
            "영어가 어려워서 늘 포기했던 분들께서 영어공부를 재밌게 시작하셨으면 하는 바램에서 만든 클래스입니다.",
            "항상 많은 것을 가르쳐드리고 싶은 마음에 늘 조금 어려운 수업을 열었던 것 같습니다. 이번에는 정말 초보분들께서 따라오시고, 머리속에 남을 수 있도록 수업을 짰습니다. 선생님 3분 또한 그 무엇보다 ‘인성’을 보고 선택하였답니다. 여러분들께서 실수를 하셔도, 틀리셔도 털털하게 웃으시며 고쳐주실 분들이라 믿기에 초보반을 맞겼답니다. 회화를 심플하고 핵심적인 패턴으로 배우고 억양과 발음까지 자연스럽게 습득하실 수 있는 클래스! 웃기고 재밌는 주인공 엠마의 한국살이 이야기, 편한 마음으로 즐겁게 배우세요! ",
          ],
          classMaterial: "수업 커리큘럼 & 수업 자료 ",
          viewDetail: "상세내용",
          showViewDetail: true,
          viewTwo: "수업시간표 보기",
          document: "ce_Beginner_Explanation.14f86fc1.png",
        },
        {
          image: "image 1.svg",
          title: "비즈니스 수업 ",
          titlebase: " Business Class",
          showViewDetail: false,
          content: [
            "샤넬 (Channel) 같은 국내 외국계 대기업들에서 비즈니스 강의를 벌써 하고 계신 Jackie 선생님과 영어교육을 전공하시고 비즈니스 영어를 전문으로 가르치시는 ‘한국말 잘하는 미국인’ Sarah 선생님께서는 이번 8주 코스에 ‘인터뷰’ 를 주제로, 집중적으로 어떻게 하면 스스로를 잘 어필할 수 있으며, Application과 Resume를 잘 작성하는 방법 등, 인터뷰 ACE 하는 방법을 가르쳐 주실겁니다. 스스로가 취준생이거나 또는 외국 업계로 이직을 생각하시는 분들, 또는 영어 면접을 준비하고 계시는 모든 분들께 강추드리는 클래스 입니다.",
          ],
          classMaterial: "수업 커리큘럼 & 수업 자료 ",
          viewDetail: "상세내용",
          viewTwo: "수업시간표 보기",
          document: "ce_BusinessClass.17406aeb.png",
        },
        {
          image: "IMG_8287.png",
          title: "중,고급수업 ",
          titlebase: " Advanced Class",
          showViewDetail: true,
          content: [
            " 논란이 되었던 ‘핫이슈’ 뉴스 기사를 원어민 미국 선생님들과 함께 파헤쳐 보는 클래스입니다. 벌써 의사소통이 조금 되지만, 영어를 다시 연습하고, 말을 더 원활하게 하고 싶거나, 어휘력을 늘리고 싶다면 바로 이 클래스를 추천드립니다. \
수업 전체가 영어로 진행되며, 난이도가 있는 단어나 어휘는 따로 한번씩 영어로 뜻 설명을 드립니다. 테슬라와 비트코인, 이스라엘과 팔레스타인의 영토 분쟁에 대해서, AI 로봇, 사이코와 소시오패스의 차이점 등등 성인들이 관심을 가질 만한 흥미진진한 토픽들로 구성되어 있습니다. 영어를 어느 정도 하시지만 더욱 더 잘하고 싶으신가요? 데이빗쌤과 메디쌤의 Advanced Class를 추천드립니다.",
            "두 분다 미국분들이시다.",
          ],
          english: "이 수업은 영어로만 진행됩니다.",
          classMaterial: "수업 커리큘럼 & 수업 자료 ",
          viewDetail: "상세내용",
          viewTwo: "수업시간표 보기",
          document: "ce_Advanced_Explanation.2b0fd396.png",
        },
        {
          image: "IMG_8271.png",
          title: "스페셜 수업  ",
          titlebase: " Special Class",
          showViewDetail: false,
          content: [
            "미스터 선샤인 뿐만 아니라 수많은 영화, 드라마를 유명 연애들과 찍은 덴마크 배우 라쓰머쓰 형님의 죽을 뻔 했던 이야기부터 시작해서 이스라엘에서 여자 장교로 군복무를 했던 니콜의 흥미로운 이스라엘 이야기들, 네덜란드에서 온 외국인 모델 요한의 이야기들, 켄야에서온 레아의 아프리카 이야기, 싱가폴에서온 엔젤린의 이야기, 미국에서 온 크리스쳔 등 다양한 선생님들의 ‘문화’ 수업을 매주 금요일 오후에 모든 수강생들에게 선물해드립니다. 그 나라에 대해서 문화적으로 궁금하신 부분이 있으시다면 미리 질문 준비해서 참석해주세요!",
          ],
          classMaterial: "수업 커리큘럼 & 수업 자료 ",
          viewDetail: "상세내용",
          viewTwo: "수업시간표 보기",
          document: "ce_SpecialEvent.3fb28e42.png",
        },
      ],
    };
  },
  methods: {
    openCalendar() {
      this.$refs["calendar-modal"].show();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&family=Roboto:wght@300;400&display=swap");

.heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 57px;
}

.base-title {
  font-size: 18px;
  font-weight: 600;
  opacity: 0.4;
}

.content {
  font-family: "Fugaz One", cursive;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
  font-weight: normal;
  font-size: 16px;
  color: rgb(0, 0, 0);
}

.ceo-name {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 16px;
}

.english {
  font-family: "Poppins", sans-serif;
  color: rgba(255, 42, 88, 1);
  font-weight: 900;
}

.img1 {
  width: 60%;
}
.img2 {
  width: 45%;
  left: 50px;
  height: auto;
}
.mask {
  width: 40px;
}

.see-detail {
  background-color: #ff2a58 !important;
  color: white !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  font-size: 20px !important;
}

.see-detail:hover {
  background-color: white !important;
  color: #ff2a58 !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  font-size: 20px !important;
}

.row-section {
  /* max-height: 500px; */
  min-height: 600px;
}
.triangle {
  animation: 20s triangleimg infinite;
}
@keyframes triangleimg {
  0% {
    top: 15%;
    left: 5%;
  }

  50% {
    top: 70%;
    left: 20%;
  }

  100% {
    top: 75%;
    left: 70%;
  }
}
.circle {
  width: 20px;
  animation: 50s circleimg infinite;
}
@keyframes circleimg {
  0% {
    top: 90%;
    left: 50%;
  }

  50% {
    top: 50%;
    left: 40%;
  }

  100% {
    top: 20%;
    left: 15%;
  }
}
.triangle-sm {
  animation: 50s trianglesm infinite;
}
@keyframes trianglesm {
  0% {
    top: 85%;
    right: 10%;
  }

  50% {
    top: 35%;
    right: 20%;
  }

  100% {
    top: 15%;
    right: 2%;
  }
}
.circle-inner {
  width: 50px;
  animation: 50s triangleinner infinite;
}
@keyframes triangleinner {
  0% {
    top: 5%;
    left: 85%;
  }

  50% {
    top: 15%;
    left: 30%;
  }

  100% {
    top: 45%;
    left: 65%;
  }
}
.triangle-lg {
  animation: 50s trianglelg infinite;
  width: 50px;
}
@keyframes trianglelg {
  0% {
    top: 10%;
    left: 30%;
  }

  50% {
    top: 40%;
    left: 70%;
  }

  100% {
    top: 80%;
    left: 30%;
  }
}

.cc {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 900;
  color: rgb(0, 0, 0);
  opacity: 0.7;
}

.view {
  color: rgba(255, 42, 88, 1);
  opacity: 1;
}

.view:hover {
  color: rgba(255, 42, 66, 1);
  opacity: 1;
}

.controller {
  top: 50%;
  left: 0%;
  z-index: 20;
}

.rightarrow {
  top: 50%;
  right: 0%;
  z-index: 20;
}

.indicators {
  width: 18px;
  height: 18px;
  border: none;
  border-radius: 25px;
  background-color: rgba(194, 190, 191, 1);
}

.active-circle {
  background-color: rgba(255, 42, 88, 1);
}
.position-relative {
  max-height: fit-content !important;
}
</style>
