<template>
  <div class="dots w-75 m-auto pb-5">
    <!-- <p style="display: none">{{ time }}</p> -->
    <b-row class="grid-first-row">
      <b-col
        data-aos="zoom-in"
        cols="3"
        style="width: 150px; height: auto"
        v-for="(image, index) in images.slice(0, 4)"
        :key="index"
      >
        <div @click="videoPlayback(image.url)">
          <Videocards :image="image" />
        </div>
      </b-col>
    </b-row>

    <b-row class="">
      <b-col class="content pt-0 mt-5 mb-0 pb-0" cols="6">
        <span class="team-header">TEAM</span>
        <span class="mrbro-header px-3">Mr.Bro</span>
        <p class="teachers-header">Fantastic 14 Teachers</p>
        <p class="mt-3" style="font-size: 16px">
          Mr.Bro 팀의 멋진 14명의 선생님들과 함께 즐거운 Live Teaching 클래스를
          통해 영어회화 실력을 높여보세요!
        </p>
        <div class="mob-card">
              <b-container fluid class="w-75 m-auto expert-space ">
                <b-row>
                  <b-col md="12" lg="12" sm="12">
                    <button
                      size="sm"
                      class="expert-btn position-relative"
                      @click="$refs['calendar-modal'].show()"
                    >
                      <p class="bt-txt z-index-front position-relative pt-4">
                        모든 수업 스케쥴
                      </p>
                      <p class="bt-txt2 z-index-front position-relative">
                        See all class schedule
                      </p>
                      <p class="bt-txt-3 mb-0 text-right mr-5">확인하기</p>
                      <img
                        class="arrow z-index-front position-relative"
                        src="../assets/newimage/arrow_back.svg"
                        alt="arrow"
                      />
                    </button>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <b-modal body-class="calander-modal" content-class="p-0" scrollable ref="calendar-modal" centered hide-footer hide-header size="xl">
                <img
            src="../assets/Images/Schedule_Table.svg"
            alt="scheduled_table"
            class="calendar"
          />    
            </b-modal>
        <!-- <b-modal
          ref="calendar-modal-ref"
          id="calendar-modal"
          scrollable
          centered
          title="BootstrapVue"
          hide-header
          hide-footer
          size="xl"
        >
          <img
            src="../assets/Images/Schedule_Table.svg"
            width="100%"
            height="100%"
            alt="scheduled_table"
          />
        </b-modal> -->
            <b-modal ref="video-modal" centered size="xl" hide-header hide-footer>
              <div
            class="embed-responsive embed-responsive-16by9 position-relative"
          >
              <img
              src="../assets/newimage/modal-icon.svg"
              alt="Mr.Bro"
              width="100px"
              class="position-absolute logo"
            />
            <iframe
              :src="currentUrl"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </iframe>
            </div>
            </b-modal>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col
            data-aos="zoom-in"
            class="w-75"
            v-for="(image, index) in images.slice(4, 6)"
            :key="index"
          >
            <div @click="videoPlayback(image.url)">
              <Videocards :image="image" />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            data-aos="zoom-in"
            class="w-75"
            v-for="(image, index) in images.slice(6, 8)"
            :key="index"
          >
            <div @click="videoPlayback(image.url)">
              <Videocards :image="image" />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="grid-first-row">
      <b-col
        data-aos="zoom-in"
        cols="3"
        v-for="(image, index) in images.slice(8, 12)"
        :key="index"
      >
        <div @click="videoPlayback(image.url)">
          <Videocards :image="image" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Videocards from "./Videocards";
import Calendar from "./Calendar";
export default {
  name: "VideoscardWrapper",
  data() {
    return {
      images: [
        //LINE 1
        {
          logo: "alex.png",
          url: this.$store.getters.videos.alex,
        },
        {
          logo: "erin.png",
          url: this.$store.getters.videos.erin,
        },
        {
          logo: "prince.png",
          url: this.$store.getters.videos.prince,
        },
        {
          logo: "bo.png",
          url: this.$store.getters.videos.bo,
        },
         {
          logo: "miso.png",
          url: this.$store.getters.videos.miso,
        },
        {
          logo: "lara.png",
          url: this.$store.getters.videos.lara,
        },
        {
          logo: "jackie.png",
          url: this.$store.getters.videos.jackie,
        },
        {
          logo: "sarah.png",
          url: this.$store.getters.videos.sarah,
        },
        //LINE 2
        
        {
          logo: "david.png",
          url: this.$store.getters.videos.david,
        },
        // {
        //   logo: "joran.png",
        //   url: this.$store.getters.videos.joran,
        // },
        //LINE 3
        {
          logo: "maddie.png",
          url: this.$store.getters.videos.maddie,
        },
        {
          logo: "rasmouth.png",
          url: this.$store.getters.videos.rasmouth,
        },
       
        //LINE 4
        
        
        {
          logo: "nicole.png",
          url: this.$store.getters.videos.nicole,
        }
        // {
        //   logo: "musosem.png",
        //   url: this.$store.getters.videos.musosem,
        // }
      ],
      currentUrl: "",
      // time: "",
      // interval: null,
    };
  },
  components: {
    Videocards,
    Calendar,
  },
  methods: {
    openCalendar() {
      this.$refs["calendar-modal-ref"].show();
    },
    videoPlayback(url) {
      // console.log(url)
      this.currentUrl = url;
      this.$refs['video-modal'].show();
    },
    // shufflePhotos(arr) {
    //   for (let i = arr.length - 1; i > 0; i--) {
    //     let j = (Math.floor(Math.random()) * 10000000000) % (i + 1);
    //     let temp = arr[i];
    //     arr[i] = arr[j];
    //     arr[j] = temp;
    //   }
    //   return arr;
    // },
    // timerCalculate() {
    //   this.time = Date.now();
    // },
  },
  // watch: {
  //   time: function () {
  //     this.images = this.shufflePhotos(this.images);
  //   },
  // },
  // created() {
  //   this.interval = setInterval(this.timerCalculate, 4000);
  // },
};
</script>

<style scoped>
.team-header,
.teachers-header,
.mrbro-header {
  font-weight: 900;
  font-size: 29px;
}

.calendar {
  /* object-fit: cover;
  width:100%;
  height: auto; */
  min-width: 300px;
  width: 100%;
  aspect-ratio: 1/1;
  /* width: 100%;
  height: 100%; */
}

.logo {
  top: 2em;
  left: 2em;
  z-index: 2020;
}

.mrbro-header {
  color: #ff2a58;
}
.meet {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
}
.content {
  margin-top: 180px;
}
.card-first {
  margin-top: 80px;
  margin-left: 20px;
  margin-right: 10px;
}
.mr-bro-x {
  z-index: 2000;
}

.modal-body {
  padding: 0 !important;
}

.grid-first-row {
  align-items: center;
  justify-items: center;
}
.arrow {
  position: relative;
  bottom: -6%;
  left: 36%;
}

.expert-space {
  position: relative;
  width: 75%;
  top: 10%;
}

.expert {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  color: #242424;
}

.content {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
  color: #535353;
}

.expert-btn {
  background: #ffd602;
  width: 80%;
  padding-top: 2em;
  margin-top: 5em;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5%;
}

.z-index-front {
  z-index: 20;
}
.expert-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 20%;
  width: 20%;
  height: 100%;
  transform: skew(-15deg) rotate(-179.71deg);
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 99.98%),
    #ffd602;
  z-index: 1;
  opacity: 0.2;
}
.expert-btn::after {
  content: "";
  position: absolute;
  top: 0;
  right: 20%;
  width: 20%;
  height: 100%;
  transform: skew(-15deg) rotate(-179.71deg);
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 99.98%),
    #ffd602;
  z-index: 1;
  opacity: 0.2;
}

.bt-txt {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 28px;
  padding-top: 45%;
  line-height: 18px;
  color: black;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.bt-txt2 {
  font-family: SeoulNamsan CEB;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 0;
  color: #ffffff;
}
.bt-txt-3 {
  font-size: 28px;
  font-weight: bolder;
  color: black;
}
@media (max-width: 991.98px) {
.mob-card{
  width: 100%;
}
.expert-btn{
  padding: 0%;
}
.bt-txt{
  font-size: 16px;
}
.bt-txt-3{
  font-size: 16px;
}
.expert-btn{
  margin-top: 0%;
}
.expert-space{
  width: 50%;
}
.bt-txt2{
  font-size: 16px;
}
}
</style>