<template>
  <b-row class="h-100 mt-5">
    <b-colxx xxs="10" md="5" class="mx-auto my-auto">
      <div class="position-relative image-side">
        <router-link tag="a" to="/"
          ><img
            src="@/assets/newimage/Group 1.svg"
            alt=""
            class="d-inline-block align-text-top"
        /></router-link>
        <p class="white mb-0">
          Please use this form to register. <br />If you are a member, please
          <router-link tag="a" to="/auth/login" class="white">login</router-link
          >.
        </p>
      </div>
      <div class="form-side">
        <router-link tag="a" to="/"><span class="logo-single" /></router-link>
        <h2 class="mb-4 text-center">Register</h2>
        <!-- @submit.prevent="formSubmit" -->
        <b-form
          class="av-tooltip tooltip-label-bottom"
        >
          <b-form-group :label="'Fullname'" class="has-float-label mb-4">
            <b-form-input
              type="text"
              v-model="$v.form.name.$model"
              :state="!$v.form.name.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.name.required"
              >Please enter your name</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-else-if="!$v.form.name.minLength || !$v.form.name.maxLength"
              >Your name must be between 3 and 40
              characters</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group :label="'Email'" class="has-float-label mb-4">
            <b-form-input
              type="text"
              v-model="$v.form.email.$model"
              :state="!$v.form.email.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.email.required"
              >Please enter your email address</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else-if="!$v.form.email.email"
              >Please enter a valid email address</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else-if="!$v.form.email.minLength"
              >Your email must be minimum 4 characters</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group :label="'Password'" class="has-float-label mb-4">
            <b-form-input
              type="password"
              v-model="$v.form.password.$model"
              :state="!$v.form.password.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.password.required"
              >Please enter your password</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-else-if="
                !$v.form.password.minLength || !$v.form.password.maxLength
              "
              >Your password must be between 4 and 16
              characters</b-form-invalid-feedback
            >
          </b-form-group>
          </b-form>
          <ul style="list-style-type: none;">
            <li>
              <b-form-checkbox :checked="agree_all" @change="checkboxSelect('agree_all', $event)" class="d-inline"></b-form-checkbox>
            <label class="all-check ml-1 d-inline" for="flexCheckDefault">
              모두 동의합니다.
            </label>
            <ul style="list-style-type: none;" class="pl-2">
              <li>
                <b-form-checkbox :checked="agree_privacy_policy" @change="checkboxSelect('agree_privacy_policy', $event)" class="d-inline"></b-form-checkbox>
            <label class="all-check d-inline" for="flexCheckDefault">
              (필수) <b-button variant="link" class="p-0 link" @click="$refs['terms_of_use_modal'].show()">이용약관</b-button>과
              <b-button variant="link" class="p-0 link" @click="$refs['privacy_policy_modal'].show()">개인정보 수집 및 이용</b-button>에
              동의합니다.
            </label>
              </li>
              <li>
                <b-form-checkbox :checked="agree_terms_of_use" @change="checkboxSelect('agree_terms_of_use', $event)" class="d-inline"></b-form-checkbox>
            <label class="all-check d-inline" for="flexCheckDefault">
              (필수) 만 14세 이상입니다. 만 19세 미만의 미성년자가 결제 시
              법정대리인이 거래를 취소할 수 있습니다.
            </label>
              </li>
              <li>
                <b-form-checkbox :checked="optional" @change="checkboxSelect('optional', $event)" id="optional" class="d-inline"></b-form-checkbox>
            <label class="all-check d-inline" for="optional">
              (선택) 이메일 및 SMS 마케팅 정보 수신에 동의합니다. 회원은
              언제든지 회원 정보에서 수신 거부로 변경할 수 있습니다
            </label>
              </li>
            </ul>
            </li>
          </ul>
          <div class="d-flex justify-content-end align-items-center mt-4">
            <b-button
              type="submit"
              variant="danger"
              size="lg"
              class="btn-shadow"
              :disabled="!(agree_terms_of_use && agree_privacy_policy)"
              @click="formSubmit"
              >회원가입</b-button
            >
          </div>
        <!-- </b-form> -->
      </div>
    </b-colxx>
    <b-modal  body-class="bg-white" header-class="bg-white" size="lg" scrollable hide-footer hide-header ref="terms_of_use_modal">
      <terms-of-use></terms-of-use>
    </b-modal>
    <b-modal size="lg"  body-class="bg-white" header-class="bg-white" scrollable hide-footer hide-header ref="privacy_policy_modal">
      <privacy-policy></privacy-policy>
    </b-modal>
    <b-modal class="modal-2" body-class="bg-white" header-class="bg-white" ref="register_notification_modal" hide-footer :title="notiModalTitle">
      <span>{{notiModalMsg}}</span>
      <b-button v-if="notiModalError" size="sm" class="mt-4" variant="warning" block @click="hideModal">Ok</b-button>
      <b-button v-if="!notiModalError" size="sm" class="mt-4" variant="link" block @click="gotoLogin">Click here to Login</b-button>
    </b-modal>
  </b-row>
</template>
<script>
import { loginPage } from "../../constants/config";
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import TermsOfUse from '../../components/TermsOfUse';
import PrivacyPolicy from '../../components/PrivacyPolicy';
const {
  required,
  maxLength,
  minLength,
  email,
} = require("vuelidate/lib/validators");
export default {
  data() {
    return {
      agree_all: false,
        optional: false,
        agree_privacy_policy: false,
        agree_terms_of_use: false,
      form: {
        name: "",
        email: "",
        password: "",
      },
      notiModalTitle: '',
      notiModalMsg: '',
      notiModalError: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        email,
        minLength: minLength(4),
      },
      password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(4),
      },
      name: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(3),
      },
    },
  },
  components: {
    'terms-of-use':TermsOfUse,
    'privacy-policy': PrivacyPolicy
  },
  computed: {
    ...mapGetters(["registerSuccess", "processing", "registerError"]),
  },
  checkbox: [],
  methods: {
    ...mapActions(["register"]),
    formSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.register({
          name: this.form.name,
          email: this.form.email,
          password: this.form.password,
        });
      }
    },
    checkboxSelect(k, v){
      this[k] = v;
      if(k === 'agree_all' && v) {
        this.optional = true;
        this.agree_privacy_policy = true;
        this.agree_terms_of_use = true;
      } else if(k === 'agree_privacy_policy') {
        this.agree_privacy_policy = v;
      } else if(k === 'agree_terms_of_use') {
        this.agree_terms_of_use = v;
      } else if(k === 'optional') {
        this.optional = v;
      } else {
        this.optional = false;
        this.agree_privacy_policy = false;
        this.agree_terms_of_use = false;
      }
      if(this.optional && this.agree_privacy_policy && this.agree_terms_of_use) {
        this.agree_all = true;
      } else {
        this.agree_all = false;
      }
    },
    hideModal() {
      this.$refs['register_notification_modal'].hide();
    },
    gotoLogin() {
      this.$refs['register_notification_modal'].hide();
      this.$router.push('/auth/login');
    }
  },
  watch: {
    registerSuccess(val) {
      if (val) {
        this.notiModalTitle="축하드립니다. 회원 등록되었습니다~^^";
        this.notiModalMsg="등록하신 이메일 주소로 발송된 인증 메일을 확인해주세요!";
        this.notiModalError = false;
        this.$refs['register_notification_modal'].show()
      }
    },
    registerError(val) {
      if (val != null) {
        this.notiModalTitle="Register User Error";
        this.notiModalMsg=val;
        this.notiModalError = true;
        this.$refs['register_notification_modal'].show()
      }
    },
  }
};
</script>
<style scoped>
.btn-shadow {
  font-size: 18px;
}
.all-check, .link {
  font-size: 10px !important;
  font-weight: 400;
}

.btn-shadow{
  background-color: #FF2A58;
}
</style>