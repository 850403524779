<template>
    <b-card class="card h-100 mt-3" :style="`background: ${background}`">
      <div class="row">
        <div class="col">
          <img class="img" :src="require('../assets/newimage/' + card.image)" alt="" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="live font-size-in-xs-sm">{{card.title}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div>
            <p class="nemo font-size-in-xs-xs">
             {{card.content}}
            </p>
          </div>
        </div>
      </div>
    </b-card>
</template>

<script>
export default {
  name: "Cards",
  props: ["card","background"]
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&family=Roboto:wght@300;400&display=swap');

.col {
  text-align: center;
}
.card {
  border-radius: 10px;
  box-shadow:  0px 8px 5px #E6DEDE;
  border: none;
}

/* .size {
  height: 19.5rem;
} */


.img {
  height: auto;
  width: 80px;
}
.live {
  color: #242424;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
}
.nemo{
  font-family: 'Fugaz One', cursive;
  font-family: 'Roboto', sans-serif;
  line-height: 16.41px;
  font-weight: normal;
  font-size: 14px;
  color: rgb(0,0,0,1);
}
</style>

